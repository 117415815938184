import emailValidator from 'email-validator';
import { useContext, useEffect, useState } from 'react';
import { useAlert } from 'src/alert';
import { ERROR_TEXT as PHONE_ERROR_TEXT } from 'src/components/PhoneNumberField';
import { ApiContext } from 'src/contexts/ApiContext';
import {
  useConfig,
  usePlanDetails,
  useQueryParam,
} from 'src/hooks';
import { PlanDetails } from 'src/types';
import { cleanPhoneNumber, validatePhoneNumber } from 'src/utils';

interface Data {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  phoneNumber: string;
}

interface Errors {
  firstName?: string | null | false;
  lastName?: string | null | false;
  email?: string | null | false;
  password?: string | null | false;
  phoneNumber?: string | null | false;
}

function getCleanData(data: Data): Data {
  return Object.entries(data).reduce((acc, [key, value]) => {
    switch (key) {
      case 'email': {
        return {
          ...acc,
          email: value.trim(),
        };
      }
      default: {
        return acc;
      }
    }
  }, data);
}

function validateForm(data: Data): Errors {
  const errors: Errors = {};

  const cleanData = getCleanData(data);

  if (cleanData.password.length < 8) errors.password = 'Please choose a longer password';
  if (!cleanData.firstName.length) errors.firstName = 'Please enter a first name';
  if (!cleanData.lastName.length) errors.lastName = 'Please enter a last name';

  if (cleanData.phoneNumber && !validatePhoneNumber(cleanData.phoneNumber)) {
    errors.phoneNumber = PHONE_ERROR_TEXT;
  }
  if (!cleanData.email.length || !emailValidator.validate(cleanData.email)) {
    errors.email = 'Please enter a valid email address';
  }
  return errors;
}

async function validatePreviewId(previewId: string): Promise<string | null> {
  try {
    await window.apis['instance-api'].instances.getInstance({
      id: previewId,
    });
    return null;
  } catch (err) {
    // @ts-expect-error TODO
    switch (err.statusCode) {
      case 404:
        return 'There\'s no preview with this ID in our system';
      case 401:
        return 'This preview is already linked to some else\'s account!';
      default:
        throw err;
    }
  }
}

function getBillingParamsFromUrl(href: string): {
  couponCode: string | null,
  planId: string | null,
} {
  try {
    const url = new URL(href);
    return {
      couponCode: url.searchParams.get('couponCode'),
      planId: url.searchParams.get('planId'),
    };
  } catch (err) {
    return {
      couponCode: null,
      planId: null,
    };
  }
}

export function useCustomPlan() {
  const { appRoot } = useConfig();
  const { swaggerInitialized } = useContext(ApiContext);
  const redirectPath = useQueryParam('redirect');
  const redirectUrl = `${appRoot}${redirectPath}`;
  const { couponCode, planId } = getBillingParamsFromUrl(redirectUrl);
  const allPlanDetails = usePlanDetails();
  const planDetails = allPlanDetails.find((p) => planId && p.planIds.includes(planId));
  const [customPlan, setCustomPlan] = useState<PlanDetails|null>(null);
  const [showCouponApplied, setShowCouponApplied] = useState(false);

  useEffect(() => {
    async function fetchCustomPlanDetails(
      swaggerInitialized: boolean,
      couponCode: string | null,
      planId: string | null,
    ) {
      if (swaggerInitialized && couponCode && planId && planDetails) {
        try {
          const res = await window.apis['billing-api'].fees.getFees({
            code: couponCode,
            planId,
          });
          setCustomPlan({
            ...planDetails,
            recurringCostCents: res.obj.recurringFee * 100,
          });
          setShowCouponApplied(true);
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err);
        }
      } else {
        setCustomPlan(planDetails ?? null);
      }
    }
    fetchCustomPlanDetails(swaggerInitialized, couponCode, planId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swaggerInitialized, couponCode, planId]);

  return {
    customPlan,
    hasCoupon: couponCode != null && planId != null,
    showCouponApplied,
  };
}

export function useRegistration() {
  const { appRoot } = useConfig();
  const variant = useQueryParam('variant');
  const defaultRedirect = variant === 'redirect-to-editor'
    ? '/onboarding/editor?variant=optional-steps'
    : '/dashboard';
  const redirectPath = useQueryParam('redirect') || defaultRedirect;
  const redirectUrl = `${appRoot}${redirectPath}`;
  const {
    couponCode: redirectCouponCode,
    planId: redirectPlanId,
  } = getBillingParamsFromUrl(redirectUrl);
  const couponCode = useQueryParam('couponCode') || redirectCouponCode;
  const planId = useQueryParam('planId') || redirectPlanId;

  const alert = useAlert();
  const previewId = useQueryParam('previewId');
  const [data, setData] = useState<Data>({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    phoneNumber: '',
  });
  const [showInputErrors, setShowInputErrors] = useState(false);
  const [inputErrors, setInputErrors] = useState<Errors>({});
  const [showConflictError, setShowConflictError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (key: string, value: string) => {
    setData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  useEffect(() => {
    setInputErrors(validateForm(data));
  }, [data]);

  useEffect(() => {
    setShowConflictError(false);
  }, [data.email]);

  const handleRegister = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (Object.values(inputErrors).filter(Boolean).length) {
      setShowInputErrors(true);
      return;
    }
    setLoading(true);
    if (previewId) {
      try {
        const previewIdError = await validatePreviewId(previewId);
        if (previewIdError) {
          alert.error(previewIdError);
          setLoading(false);
          return;
        }
      } catch (err) {
        alert.error('Something went wrong. The Bizwise team has just been notified and the issue will be fixed shortly!');
        setLoading(false);
        throw err;
      }
    }
    let timeZone;
    try {
      ({ timeZone } = Intl.DateTimeFormat().resolvedOptions());
    } catch (err) {
      // Do nothing
    }
    try {
      const referredBy = await window.growsurf?.getReferrerId() || undefined;
      const cleanData = getCleanData(data);
      await window.apis['auth-api'].auth.register({
        accountDetails: {
          firstName: cleanData.firstName,
          lastName: cleanData.lastName,
          companyName: 'No Org Name Provided!',
          email: cleanData.email,
          password: cleanData.password,
          phoneNumber: cleanPhoneNumber(cleanData.phoneNumber) || undefined,
          previewId: previewId || undefined,
          timeZone,
          referredBy,
          couponCode: couponCode || undefined,
          planId: planId || undefined,
        },
      });
      window.location.href = redirectUrl;
    } catch (err) {
      // @ts-expect-error TODO
      if (err.statusCode === 409) {
        setShowConflictError(true);
      } else {
        alert.error('Something went wrong! Please try again later.');
      }
    }
    setLoading(false);
  };

  return {
    data,
    inputErrors,
    loading,
    showInputErrors,
    handleChange,
    handleRegister,
    showConflictError,
  };
}
